<template>
  <Modal
    name="spla-report-edit"
    :title="$t('SPLA report for {period}', { period: report.period })"
    class="fixed-top checkout-modal"
    @before-open="onOpen"
  >
    <div class="product-section">
      <Loader v-if="isLoading" loading full />

      <div class="modal-heading pb-3">
        <h2>{{ report.period }}</h2>
      </div>
      
      <form @submit="onSubmit">
        <div v-if="errors._message" class="alert alert-danger">
          {{ errors._message }}
        </div>

        <div class="row">
          
        <InputBox
          id="userid"
          v-model="form.exceptioncount"
          :focus="true"
          :label="$t('Exception Count')"
          type="number"
          :errors="errors"
          auto-select
          />
        </div>
      </form>

    </div>
    <template #footer>
      <div class="row">
        <div class="col">
          <SupportBlock minified />
        </div>
        <div class="col-auto">
          <button
            class="btn btn-outline-primary px-5 ml-auto mr-3"
            @click="onClose"
          >
            {{ $t('Close') }}
          </button>
          <button
            class="btn btn-primary px-5 ml-auto ml-3"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('Save') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import InputBox from '@/components/InputBox.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'ExtendTrial',
  components: {
    Loader,
    Modal,
    SupportBlock,
    InputBox
  },
  data() {
    return {
      errors: {},
      report: {},
      form: {
        period: '',
        reportingdate: '',
        totallicense: '',
        exceptioncount: 0,
        invoicedate: '',
        paiddate: ''
      },
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['spla/editReport'];
    },
    user() {
      return this.$store.state.core.user;
    },
  },
  methods: {
    async onOpen({ params }) {
      this.report = params;
      this.form.exceptioncount = params.exceptioncount;
    },
    async onSubmit(e) {
      
      e.preventDefault();
      e.stopPropagation();
      
      this.errors = {};

      const response = await this.$store.dispatch('spla/editReport', {
        ...this.form,
        id: this.report.id
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return;
      }

      this.$toast.info(this.$t('Successfully updated the SPLA excepction count!'));
      this.$modal.hide('spla-report-edit');
      
    },
    onClose() {
      this.$modal.hide('spla-report-edit');
    },
  },
};
</script>