<template>
  <div class="billing-wrapper">
    <section class="section-header">
      <div class="container-lg">
        <h2>
          <Icon class="mr-3" family="fal" name="signal" />
          {{ $t('SPLA Reporting') }}
        </h2>
      </div>
    </section>
    
    <main role="main" class="main-wrapper">
      <Loader full :loading="loading" />
      
      <Table
        :headers="splaHeaders"
        :data="reports"
        class="billing-table"
        hover
        :query="query"
        @click:row="(event) => $modal.show('spla-report', event.data)"
      >
        <template #[`item.exceptioncount`]="{ item }">
          <div>{{item}}</div>
        </template>
        <template #[`item.invoicedate`]="{ item }">
          <div>{{ typeof item !== 'object' ? item : $t('Not invoiced') }}</div>
        </template>
        <template #[`item.paiddate`]="{ item }">
          <div>{{ typeof item !== 'object' ? item : $t('Not paid') }}</div>
        </template>
      </Table>
      <div v-if="meta && reports.length < meta.total" class="row">
        <div class="col-md-12 text-center">
          <button class="btn btn-primary mx-auto my-4" @click="loadMore">{{$t('Load More')}}</button>
        </div>
      </div>
    </main>
    <SplaReportModal />
    <SplaReportEditModal />
  </div>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import Icon from 'common/components/Icon.vue';
import Table from '../components/Table/Table';
import SplaReportModal from '../components/Modals/SplaReport.vue';
import SplaReportEditModal from '../components/Modals/SplaReportEdit.vue';

import { splaHeaders } from '../constants/spla';

export default {
  name: 'Billing',
  components: {
    Loader,
    Icon,
    Table,
    SplaReportModal,
    SplaReportEditModal
  },
  data() {
    return {
      query: '',
      splaHeaders,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading['spla/getReports'] || this.$store.state.loading['spla/getNextReports'];
    },
    user() {
      return this.$store.state.core.user;
    },
    reports() {
      return this.$store.state.spla.reports;
    },
    meta() {
      return this.$store.state.spla.meta;
    },
    orderOptions() {
      return [
        {
          name: 'Edit Exception',
          icon: 'edit',
          action: 'editException',
        }
      ];
    },
  },
  mounted() {
    this.$store.dispatch('spla/getReports', { start: 0, end: 10 });
  },
  methods: {
    editException(item) {
      this.$modal.show('spla-report-edit', item)
    },
    loadMore() {
      this.$store.dispatch('spla/getNextReports');
    }
  },
};
</script>

<style></style>
