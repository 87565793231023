<template>
  <Modal
    name="spla-report"
    :title="$t('SPLA reports for {period}', { period: report.period })"
    class="fixed-top checkout-modal"
    @before-open="onOpen"
  >
  
    <div class="welcome-section">
      
      <div class="modal-heading pb-3">
        <h2>{{ report.period }}</h2>
      </div>
      
      <Loader v-if="isLoading" loading full />

      <div v-if="errors._message" class="alert alert-danger">
        {{ errors._message }}
      </div>
      
      <Table
        :headers="splaSplitHeaders"
        :data="splitReports"
        class="billing-table"
        hover
        @click:row="(event) => $modal.show('spla-report', event.data)"
      >
        <template #[`item.invoicedate`]="{ item }">
          <div>{{ typeof item !== 'object' ? item : $t('Not invoiced') }}</div>
        </template>
        <template #[`item.paiddate`]="{ item }">
          <div>{{ typeof item !== 'object' ? item : $t('Not paid') }}</div>
        </template>
      </Table>
      <div v-if="meta && splitReports.length < meta.total" class="row">
        <div class="col-md-12 text-center">
          <button class="btn btn-primary btn-sm mx-auto mt-4" @click="loadMore">{{$t('Load More')}}</button>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="row">
        <div class="col">
          <SupportBlock minified />
        </div>
        <div class="col-auto">
          <button class="btn btn-outline-primary px-5 ml-auto" @click="onClose">
            {{ $t('Close') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Modal from '@/components/Modal.vue';
import Table from '@/components/Table/Table';
import { splaSplitHeaders } from '@/constants/spla';

export default {
  name: 'Checkout',
  components: {
    Loader,
    Modal,
    SupportBlock,
    Table
  },
  data() {
    return {
      errors: {},
      report: {},
      splaSplitHeaders
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['spla/getSplitReports'] || this.$store.state.loading['spla/getNextSplitReports'];
    },
    splitReports() {
      return this.$store.state.spla.splitReports.reports;
    },
    meta() {
      return this.$store.state.spla.splitReports.meta;
    },
    core() {
      return this.$store.state.core;
    },
    user() {
      return this.$store.state.core.user;
    },
  },
  methods: {
    async onOpen({ params }) {
      
      this.report = params;
      
      this.$store.dispatch('spla/getSplitReports', {
        id: this.report.id,
        start: 0, 
        end: 10
      })
      
    },
    loadMore() {
      this.$store.dispatch('spla/getNextSplitReports', {
        id: this.report.id
      })  
    },
    onClose() {
      this.$modal.hide('spla-report');
    },
  },
};
</script>
