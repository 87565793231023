export const splaHeaders = [
	{
		text: 'Month / Year Reporting',
		value: 'period',
		sortable: false,
		width: '18%'
	},
	{
		text: 'License Billable',
		value: 'licensebillable',
		sortable: false,
		width: '18%'
	},
	{
		text: 'Invoice Date',
		value: 'invoicedate',
		sortable: false,
		width: '18%'
	},
	{
		text: 'Paid Date',
		value: 'paiddate',
		sortable: false,
		width: '18%'
	}
]

export const splaSplitHeaders = [
	{
		text: 'Server Name',
		value: 'serverurl',
		sortable: false,
		width: '18%'
	},
	{
		text: 'Reporting Date',
		value: 'createdon',
		sortable: false,
		width: '18%'
	},
	{
		text: 'Licenses Used',
		value: 'count',
		sortable: false,
		width: '18%'
	},
]
